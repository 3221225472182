$(window).on('load',function(){
    $('body').addClass('is-loaded');
});

$(window).on('beforeunload',function(e){
    $('body').removeClass('is-loaded');
});

$('.js-toggleMenu').on('click',function(){
    $('body').toggleClass('is-menu');
});

/**
 * Move footer
 */

function positionFooter() {
    if($('body').outerHeight() < $(window).outerHeight()) {
        $('body').addClass('is-short-page');
    }
    else {
        $('body').removeClass('is-short-page');
    }
}

$(document).ready(positionFooter);
$(window).on('resize', positionFooter);

/**
 * Slugify text
 */
function slugify(text) {
    var a = 'æøöåàáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
    var b = 'aooaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
    var p = new RegExp(a.split('').join('|'), 'g');

    return text.toString().toLowerCase()
    .replace(/\s+/g, '-')
    .replace(p, function(c) {
         return b.charAt(a.indexOf(c));
    })
    .replace(/&/g, '-and-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
}

/**
 * Toggle subscriptions button
 */
$('input[name="item"]').on('click', function(event) {
    $('.js-submit-subscription').removeAttr('disabled');
});

/**
 * Preview image before uploading
 */
$('.js-choose-image').on('change', function(event) {
    if (this.files && this.files[0]) {
        var reader = new FileReader(),
            $this = $(this);

        reader.onload = function(event) {
            if(!$('.js-preview-image').length) {
                $this.parent().before($('<img />').addClass('js-preview-image Form-previewImage'));
            }
            $('.js-preview-image').attr('src', event.target.result);
        }

        reader.readAsDataURL(this.files[0]);
    }
});

/**
 * Confirm dialog
 */
$('.js-confirm').on('click', function(event) {
    if (!window.confirm('Är du säker?')) {
        event.preventDefault();
    }
})

/**
 * Slugify title
 */
$('[data-slugify]').on('blur', function(event) {
    var target = $(this).attr('data-slugify'),
        $target =  $('.' + target),
        slug = slugify($(this).val());

        if($target.length && !$target.attr('readonly')) {
            $target.val(slug);
        }
})

/**
 * Confirm password
 */
$('[data-confirm-password]').on('blur', function(event) {
    var target = $(this).attr('data-confirm-password'),
        $target =  $('.' + target);

    if($(this).val() != $target.val()) {
        $(this).get(0).setCustomValidity('Lösenorden matchar inte varandra');
    }
    else {
        $(this).get(0).setCustomValidity('');
    }
});

function attributeSupported(attribute) {
    return (attribute in document.createElement("input"));
}

function isSafariOnly() {
    return navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
}

// NOTE: Here's a function to reduce a full list of post codes to a list of
// "range pairs", where every pair represents a min and max number for a valid
// post code.
// NOTE: It's possible in future versions that it's equally efficient to include
// the full list of numbers here instead. This trick saves about 60% of the size
// of the list, but that's only a few KB.
//
// allPostalCodes.reduce(function(result, n) {
//     var pair = result[result.length - 1] || [n];
//
//     if (pair[pair.length - 1] === n - 1) {
//         pair = pair.length === 1 ? pair : pair.slice(0, -1);
//         pair = pair.concat(n);
//     }
//
//     return result.slice(0, -1).concat([pair]);
// }, []);

// This list of range pairs was generated from a list of postal codes that the
// client provided. The list is meant to cover all postal codes in Västra
// Götalandsregionen
var postalCodes = [
    [41101, 41140],
    [41143, 41143],
    [41249, 41276],
    [41279, 41282],
    [41285, 41285],
    [41301, 41328],
    [41346, 41346],
    [41390, 41390],
    [41451, 41483],
    [41502, 41502],
    [41505, 41505],
    [41511, 41517],
    [41522, 41528],
    [41531, 41538],
    [41541, 41548],
    [41551, 41557],
    [41561, 41568],
    [41571, 41574],
    [41647, 41681],
    [41701, 41708],
    [41711, 41711],
    [41713, 41729],
    [41742, 41749],
    [41753, 41753],
    [41755, 41758],
    [41760, 41767],
    [41830, 41843],
    [41870, 41879],
    [42130, 42135],
    [42137, 42153],
    [42157, 42163],
    [42165, 42167],
    [42170, 42174],
    [42241, 42241],
    [42243, 42259],
    [42332, 42334],
    [42336, 42343],
    [42346, 42347],
    [42349, 42356],
    [42359, 42359],
    [42361, 42361],
    [42363, 42363],
    [42370, 42373],
    [42431, 42450],
    [42453, 42457],
    [42461, 42461],
    [42465, 42472],
    [42490, 42491],
    [42530, 42539],
    [42541, 42543],
    [42565, 42565],
    [42650, 42656],
    [42658, 42659],
    [42668, 42674],
    [42676, 42677],
    [42679, 42679],
    [42734, 42740],
    [42750, 42750],
    [42830, 42837],
    [42930, 42935],
    [42941, 42944],
    [43080, 43085],
    [43130, 43151],
    [43153, 43153],
    [43159, 43164],
    [43166, 43169],
    [43190, 43190],
    [43265, 43268],
    [43330, 43339],
    [43341, 43353],
    [43360, 43370],
    [43375, 43376],
    [43430, 43437],
    [43439, 43448],
    [43450, 43452],
    [43460, 43460],
    [43479, 43479],
    [43490, 43499],
    [43530, 43533],
    [43535, 43535],
    [43537, 43544],
    [43632, 43640],
    [43642, 43645],
    [43650, 43658],
    [43730, 43736],
    [43738, 43738],
    [43740, 43742],
    [43791, 43793],
    [43832, 43836],
    [43838, 43839],
    [43851, 43854],
    [43870, 43870],
    [43891, 43898],
    [43930, 43937],
    [43951, 43955],
    [43961, 43965],
    [43971, 43975],
    [43991, 43994],
    [44130, 44146],
    [44150, 44151],
    [44155, 44158],
    [44160, 44160],
    [44163, 44163],
    [44165, 44165],
    [44170, 44175],
    [44191, 44196],
    [44230, 44245],
    [44247, 44254],
    [44260, 44260],
    [44265, 44267],
    [44270, 44275],
    [44277, 44277],
    [44290, 44298],
    [44330, 44336],
    [44338, 44345],
    [44350, 44351],
    [44360, 44361],
    [44370, 44374],
    [44391, 44392],
    [44395, 44396],
    [44430, 44432],
    [44440, 44448],
    [44452, 44455],
    [44460, 44461],
    [44465, 44465],
    [44491, 44497],
    [44532, 44534],
    [44536, 44537],
    [44552, 44552],
    [44555, 44557],
    [44632, 44638],
    [44640, 44641],
    [44691, 44697],
    [44730, 44735],
    [44737, 44737],
    [44791, 44795],
    [44830, 44837],
    [44850, 44850],
    [44891, 44892],
    [44895, 44896],
    [44930, 44936],
    [44940, 44944],
    [44950, 44951],
    [44970, 44970],
    [44990, 44990],
    [45130, 45134],
    [45140, 45145],
    [45150, 45155],
    [45160, 45163],
    [45170, 45173],
    [45175, 45179],
    [45190, 45198],
    [45204, 45205],
    [45230, 45238],
    [45240, 45240],
    [45250, 45252],
    [45260, 45260],
    [45270, 45271],
    [45290, 45297],
    [45330, 45338],
    [45340, 45341],
    [45350, 45350],
    [45391, 45393],
    [45430, 45432],
    [45491, 45495],
    [45530, 45535],
    [45541, 45541],
    [45561, 45561],
    [45591, 45593],
    [45595, 45598],
    [45631, 45634],
    [45642, 45644],
    [45647, 45647],
    [45650, 45651],
    [45655, 45655],
    [45660, 45662],
    [45691, 45694],
    [45730, 45732],
    [45740, 45743],
    [45745, 45748],
    [45750, 45751],
    [45755, 45756],
    [45760, 45761],
    [45772, 45773],
    [45791, 45791],
    [45793, 45793],
    [45795, 45797],
    [45830, 45832],
    [45840, 45840],
    [45870, 45870],
    [45891, 45894],
    [45896, 45897],
    [45930, 45933],
    [45991, 45991],
    [45993, 45994],
    [46130, 46135],
    [46137, 46144],
    [46150, 46155],
    [46157, 46168],
    [46170, 46171],
    [46173, 46173],
    [46177, 46179],
    [46191, 46199],
    [46230, 46238],
    [46240, 46242],
    [46250, 46256],
    [46260, 46262],
    [46269, 46269],
    [46273, 46273],
    [46291, 46291],
    [46293, 46297],
    [46330, 46335],
    [46371, 46372],
    [46375, 46375],
    [46392, 46397],
    [46430, 46434],
    [46440, 46440],
    [46450, 46450],
    [46461, 46466],
    [46471, 46472],
    [46491, 46495],
    [46530, 46531],
    [46591, 46591],
    [46593, 46598],
    [46730, 46732],
    [46735, 46735],
    [46740, 46740],
    [46791, 46796],
    [46830, 46834],
    [46890, 46890],
    [47130, 47133],
    [47141, 47144],
    [47150, 47151],
    [47160, 47162],
    [47170, 47173],
    [47190, 47195],
    [47197, 47198],
    [47231, 47232],
    [47291, 47298],
    [47331, 47334],
    [47391, 47399],
    [47431, 47432],
    [47470, 47471],
    [47474, 47474],
    [47491, 47496],
    [47531, 47532],
    [47535, 47537],
    [47540, 47542],
    [47545, 47545],
    [47550, 47553],
    [50330, 50338],
    [50342, 50343],
    [50430, 50435],
    [50437, 50439],
    [50441, 50447],
    [50450, 50458],
    [50460, 50468],
    [50470, 50472],
    [50475, 50475],
    [50478, 50478],
    [50490, 50497],
    [50630, 50632],
    [50634, 50644],
    [50646, 50649],
    [50670, 50670],
    [50730, 50734],
    [50740, 50745],
    [50750, 50753],
    [50760, 50765],
    [50770, 50771],
    [50790, 50790],
    [51131, 51131],
    [51141, 51142],
    [51154, 51159],
    [51161, 51163],
    [51168, 51174],
    [51191, 51199],
    [51250, 51254],
    [51260, 51265],
    [51276, 51277],
    [51290, 51295],
    [51332, 51335],
    [51350, 51350],
    [51370, 51370],
    [51391, 51397],
    [51431, 51435],
    [51440, 51444],
    [51450, 51450],
    [51452, 51455],
    [51460, 51463],
    [51470, 51470],
    [51491, 51495],
    [51532, 51535],
    [51560, 51561],
    [51570, 51570],
    [51591, 51593],
    [51630, 51634],
    [51650, 51650],
    [51677, 51677],
    [51690, 51696],
    [51733, 51737],
    [51770, 51771],
    [51791, 51796],
    [51830, 51833],
    [51840, 51843],
    [51890, 51891],
    [51930, 51931],
    [51990, 51991],
    [51994, 51997],
    [52130, 52137],
    [52140, 52147],
    [52151, 52156],
    [52160, 52164],
    [52170, 52173],
    [52175, 52175],
    [52191, 52198],
    [52230, 52237],
    [52291, 52294],
    [52330, 52341],
    [52343, 52345],
    [52360, 52362],
    [52372, 52372],
    [52374, 52378],
    [52390, 52399],
    [52430, 52432],
    [52441, 52442],
    [52491, 52493],
    [52495, 52496],
    [53030, 53030],
    [53032, 53032],
    [53130, 53143],
    [53150, 53159],
    [53170, 53171],
    [53173, 53173],
    [53175, 53175],
    [53191, 53199],
    [53230, 53238],
    [53272, 53274],
    [53292, 53296],
    [53330, 53334],
    [53372, 53372],
    [53374, 53374],
    [53391, 53392],
    [53394, 53397],
    [53430, 53432],
    [53450, 53450],
    [53460, 53462],
    [53473, 53473],
    [53490, 53496],
    [53530, 53530],
    [53591, 53593],
    [54130, 54150],
    [54152, 54157],
    [54161, 54166],
    [54170, 54171],
    [54176, 54178],
    [54191, 54198],
    [54230, 54236],
    [54240, 54245],
    [54266, 54266],
    [54272, 54275],
    [54291, 54295],
    [54330, 54337],
    [54350, 54351],
    [54391, 54395],
    [54430, 54433],
    [54450, 54450],
    [54491, 54494],
    [54530, 54535],
    [54572, 54572],
    [54590, 54593],
    [54630, 54634],
    [54672, 54673],
    [54691, 54695],
    [54730, 54731],
    [54772, 54772],
    [54791, 54793],
    [54831, 54832],
    [54873, 54874],
    [54891, 54893],
    [54931, 54931],
    [54941, 54941],
    [54991, 54993],
    [56531, 56534],
    [56591, 56594],
    [66130, 66133],
    [66140, 66143],
    [66191, 66194],
    [66230, 66237],
    [66291, 66292],
    [66295, 66295],
    [66297, 66298],
    [66630, 66632],
    [66640, 66641],
    [66691, 66695],
    [66830, 66832],
    [66840, 66840],
    [66891, 66895],
    [68130, 68138],
    [68140, 68143],
    [68150, 68155],
    [68160, 68160],
    [68191, 68196],
    [69550, 69550],
    [69593, 69594],
    [69596, 69597]
];

function onInputInvalid() {
    this.setCustomValidity("");

    var val = Number($(this).val().replace(/\s/, ""));

    if (isNaN(val)) {
        this.setCustomValidity("Vänligen mata in ett giltigt postnummer");
        return;
    }

    var validPostalCodes = postalCodes.filter(function(postalCode) {
        return val >= postalCode[0] && val <= postalCode[1];
    });

    if (!validPostalCodes.length) {
        this.setCustomValidity("Bara postnummer inom Västra Götaland är giltiga");
        return;
    }
}

$(".Form-input[name=postal]").on("blur invalid", onInputInvalid);

$(document).ready(function(){

    // $('.Form').submit(function() {
    //     var valid = true;
    //     if (!attributeSupported("required") || isSafariOnly() ) {
    //         $(this).find("input[required]").each(function() {
    //             if ( !$(this).val() ) {
    //                 valid = false;
    //                 alert("Vänligen fyll i alla fält som krävs (*)");
    //                 return false;
    //             }
    //         });
    //     }
    //     if ( valid ) {
    //         return;
    //     } else {
    //         return false;
    //     }
    // });

    $('.Form').submit(function() {
        e.preventDefault();
        grecaptcha.ready(function() {
            grecaptcha.execute('6LfhDsgpAAAAACnX8QTTHsvMEggV3Rz4qdkFSJyH', {action: 'submit'}).then(function(token) {
                var valid = true;
                if (!attributeSupported("required") || isSafariOnly() ) {
                    $(this).find("input[required]").each(function() {
                        if ( !$(this).val() ) {
                            valid = false;
                            alert("Vänligen fyll i alla fält som krävs (*)");
                            return false;
                        }
                    });
                }
                if ( valid ) {
                    return;
                } else {
                    return false;
                }
            });
        });
    });

});
